/**
 * CUSTOM SVGs
 * ADD ONLY CUSTOM SVGs HERE!!!
 *
 * Fontawesome svgs should be in fontawesomeSvgs.js!!!
 *
 * here are all custom svgs
 *
 * there are currently two sections
 * - add-content-modal icons
 * - other svgs
 *
 * please put your svg in the right section!
 *
 */

/*************************************************************************************
 *************************************************************************************
 * add-content-modal icons
 *************************************************************************************
 *************************************************************************************
 */

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const imgwithoverlay_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`
      .imgwithoverlay-cls-1{fill:#ffed00;}.imgwithoverlay-cls-1,.imgwithoverlay-cls-2{stroke-width:0px;}.imgwithoverlay-cls-3,.imgwithoverlay-cls-4{fill:none;stroke-miterlimit:10;}.imgwithoverlay-cls-4{stroke-width:2px;}.imgwithoverlay-cls-5{mask:url(#mask-overlay);}`}</style>
      <mask
        id="mask-overlay"
        x="-1.85"
        y="-13.27"
        width="66.75"
        height="66.75"
        maskUnits="userSpaceOnUse"
      >
        <path
          class="imgwithoverlay-cls-1"
          d="m64.9,20.11c0,18.43-14.94,33.37-33.37,33.37S-1.85,38.54-1.85,20.11,13.09-13.27,31.52-13.27,64.9,1.67,64.9,20.11ZM20.51,2.73c-7.27,0-13.17,5.9-13.17,13.17s5.9,13.17,13.17,13.17,13.17-5.9,13.17-13.17S27.79,2.73,20.51,2.73Z"
        />
      </mask>
    </defs>
    <g>
      <rect
        class="imgwithoverlay-cls-4"
        x="1"
        y="1"
        width="63.57"
        height="62.61"
      />
      <polygon
        class="imgwithoverlay-cls-2"
        points="1.04 63.85 15.83 34.67 30.64 42.84 41.87 24.45 64.62 63.85 1.04 63.85"
      />
      <circle class="imgwithoverlay-cls-3" cx="20.71" cy="15.66" r="10.72" />
      <line
        class="imgwithoverlay-cls-3"
        x1="20.74"
        y1="9.37"
        x2="20.74"
        y2="21.95"
      />
      <line
        class="imgwithoverlay-cls-3"
        x1="27"
        y1="15.66"
        x2="14.42"
        y2="15.66"
      />
      <g class="imgwithoverlay-cls-5">
        <rect
          class="imgwithoverlay-cls-2"
          x="1.6"
          y="14.82"
          width="63.19"
          height="1.37"
        />
      </g>
    </g>
  </svg>
);
/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const contentcarousel_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.contentcarousel-cls-1,.contentcarousel-cls-2,.contentcarousel-cls-3{fill:none;}.contentcarousel-cls-2,.contentcarousel-cls-3{stroke-miterlimit:10;}.contentcarousel-cls-3{stroke-width:2px;}.contentcarousel-cls-4{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect
        class="contentcarousel-cls-3"
        x="1"
        y="1"
        width="63.57"
        height="62.61"
      />
      <rect
        class="contentcarousel-cls-2"
        x="13.9"
        y="21.59"
        width="21.26"
        height="20.94"
      />
      <polygon
        class="contentcarousel-cls-4"
        points="13.78 42.02 18.62 31.96 23.47 34.78 27.15 28.44 34.61 42.02 13.78 42.02"
      />
      <circle class="contentcarousel-cls-4" cx="17.71" cy="25.61" r="2.02" />
      <line
        class="contentcarousel-cls-1"
        x1="37.54"
        y1="29.49"
        x2="52.5"
        y2="29.49"
      />
      <line
        class="contentcarousel-cls-1"
        x1="37.54"
        y1="34.6"
        x2="45.05"
        y2="34.6"
      />
      <polygon
        class="contentcarousel-cls-4"
        points="10.39 30.09 9.81 29.27 5.67 32.17 9.83 35.07 10.41 34.25 7.43 32.16 10.39 30.09"
      />
      <polygon
        class="contentcarousel-cls-4"
        points="55.19 30.09 55.76 29.27 59.9 32.17 55.74 35.07 55.17 34.25 58.14 32.16 55.19 30.09"
      />
    </g>
  </svg>
);
/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const image_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.image-cls-1{fill:none;stroke-miterlimit:10;stroke-width:2px;}.image-cls-2{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="image-cls-1" x="1" y="1" width="63.57" height="62.61" />
      <polygon
        class="image-cls-2"
        points="1 63.61 15.79 34.42 30.6 42.59 41.83 24.21 64.57 63.61 1 63.61"
      />
      <circle class="image-cls-2" cx="12.72" cy="17.06" r="5.11" />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const button_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.button-cls-1,.button-cls-2{fill:none;stroke-miterlimit:10;}.button-cls-3{fill:#fff;}.button-cls-3,.button-cls-4{stroke-width:0px;}.button-cls-2{stroke-width:2px;}.button-cls-5{mask:url(#mask-button);}.button-cls-6{filter:url(#luminosity-invert-noclip);}`}</style>
      <filter
        id="luminosity-invert-noclip"
        x="13.07"
        y="25.58"
        width="41.5"
        height="24.9"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feColorMatrix
          result="cm"
          values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
        />
        <feFlood flood-color="#fff" result="bg" />
        <feBlend in="cm" in2="bg" />
      </filter>
      <mask
        id="mask-button"
        x="13.07"
        y="25.58"
        width="41.5"
        height="24.9"
        maskUnits="userSpaceOnUse"
      >
        <g class="button-cls-6">
          <polygon
            class="button-cls-3"
            points="37.52 50.49 36.97 29.6 54.24 40.81 48.39 44.19 49.82 46.58 44.81 49.46 43.4 47.08 37.52 50.49"
          />
        </g>
      </mask>
    </defs>
    <g>
      <rect class="button-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <g class="button-cls-5">
        <rect
          class="button-cls-4"
          x="13.07"
          y="25.58"
          width="41.5"
          height="12.61"
        />
      </g>
      <polygon
        class="button-cls-1"
        points="39.64 45.91 38.5 32.69 49.3 40.13 45.92 42.15 46.88 43.69 43.98 45.42 43.04 43.88 39.64 45.91"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const card_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.card-cls-1,.card-cls-2,.card-cls-3{fill:none;}.card-cls-2,.card-cls-3{stroke-miterlimit:10;}.card-cls-3{stroke-width:2px;}.card-cls-4{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="card-cls-3" x="1" y="1" width="63.57" height="62.61" />
      <rect
        class="card-cls-4"
        x="11.88"
        y="43.98"
        width="13.06"
        height="5.36"
      />
      <rect
        class="card-cls-2"
        x="12.04"
        y="15.27"
        width="17.86"
        height="16.72"
      />
      <polygon
        class="card-cls-4"
        points="11.88 31.58 15.96 23.55 20.05 25.79 23.15 20.74 29.43 31.58 11.88 31.58"
      />
      <ellipse class="card-cls-4" cx="15.24" cy="18.48" rx="1.7" ry="1.61" />
      <line class="card-cls-1" x1="11.88" y1="36.49" x2="25.05" y2="36.49" />
      <line class="card-cls-1" x1="11.88" y1="40.49" x2="18.5" y2="40.49" />
      <rect
        class="card-cls-4"
        x="35.68"
        y="43.98"
        width="13.06"
        height="5.36"
      />
      <rect
        class="card-cls-2"
        x="35.84"
        y="15.27"
        width="17.86"
        height="16.72"
      />
      <polygon
        class="card-cls-4"
        points="35.68 31.58 39.76 23.55 43.85 25.79 46.95 20.74 53.23 31.58 35.68 31.58"
      />
      <ellipse class="card-cls-4" cx="39.04" cy="18.48" rx="1.7" ry="1.61" />
      <line class="card-cls-1" x1="35.68" y1="36.26" x2="48.85" y2="36.26" />
      <line class="card-cls-1" x1="35.68" y1="40.25" x2="42.29" y2="40.25" />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const download_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.download-cls-1{fill:none;stroke-miterlimit:10;stroke-width:2px;}.download-cls-2{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="download-cls-1" x="1" y="1" width="63.57" height="62.61" />
      <polyline
        class="download-cls-1"
        points="49.14 38.4 49.14 48.95 18.43 48.95 18.43 38.72"
      />
      <polygon
        class="download-cls-2"
        points="32.36 19.78 32.36 33.63 29.84 33.63 33.78 40.46 37.73 33.63 35.21 33.63 35.21 19.78 32.36 19.78"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const form_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.form-cls-1,.form-cls-2{fill:none;}.form-cls-2{stroke-miterlimit:10;stroke-width:2px;}.form-cls-3{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="form-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <line class="form-cls-1" x1="24.78" y1="11.07" x2="42.42" y2="11.07" />
      <rect class="form-cls-1" x="14.3" y="24.74" width="17.64" height="5.36" />
      <rect class="form-cls-1" x="14.3" y="16.17" width="37.98" height="5.36" />
      <rect
        class="form-cls-3"
        x="24.78"
        y="49.68"
        width="17.64"
        height="5.36"
      />
      <rect
        class="form-cls-1"
        x="34.46"
        y="24.74"
        width="17.64"
        height="5.36"
      />
      <rect
        class="form-cls-1"
        x="14.3"
        y="33.49"
        width="37.98"
        height="12.23"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const gallery_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.gallery-cls-1,.gallery-cls-2{fill:none;stroke-miterlimit:10;}.gallery-cls-2{stroke-width:2px;}`}</style>
    </defs>
    <g>
      <rect class="gallery-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <rect
        class="gallery-cls-1"
        x="8.32"
        y="17.75"
        width="12.26"
        height="12.26"
      />
      <rect
        class="gallery-cls-1"
        x="26.7"
        y="17.75"
        width="12.26"
        height="12.26"
      />
      <rect
        class="gallery-cls-1"
        x="45.09"
        y="17.75"
        width="12.26"
        height="12.26"
      />
      <rect
        class="gallery-cls-1"
        x="8.32"
        y="34.6"
        width="12.26"
        height="12.26"
      />
      <rect
        class="gallery-cls-1"
        x="26.7"
        y="34.6"
        width="12.26"
        height="12.26"
      />
      <rect
        class="gallery-cls-1"
        x="45.09"
        y="34.6"
        width="12.26"
        height="12.26"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const carousel_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.carousel-cls-1,.carousel-cls-2{fill:none;stroke-miterlimit:10;}.carousel-cls-2{stroke-width:2px;}.carousel-cls-3{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="carousel-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <rect
        class="carousel-cls-1"
        x="15.43"
        y="21.57"
        width="34.72"
        height="20.94"
      />
      <polygon
        class="carousel-cls-3"
        points="16.68 42.48 24.4 32.11 32.13 35.02 38 28.48 49.88 42.48 16.68 42.48"
      />
      <circle class="carousel-cls-3" cx="21.27" cy="26.86" r="2.67" />
      <polygon
        class="carousel-cls-3"
        points="11.39 30.09 10.81 29.27 6.67 32.17 10.83 35.07 11.41 34.25 8.43 32.16 11.39 30.09"
      />
      <polygon
        class="carousel-cls-3"
        points="54.19 30.09 54.76 29.27 58.9 32.17 54.74 35.07 54.17 34.25 57.14 32.16 54.19 30.09"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const media_carousel_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 39.12 22.22"
  >
    <g>
      <g>
        <path d="M4.27,1.76a.42.42,0,0,1,.33.13A.84.84,0,0,1,5,2.58V16.24a.37.37,0,0,1,0,.39,1.38,1.38,0,0,1-.39.3l-.33.17L4,16.73.5,15.05A.73.73,0,0,1,0,14.66.71.71,0,0,1,.17,14a.77.77,0,0,1,.39-.51.71.71,0,0,1,.64.14l2.21,1V3.94L1.2,5a.53.53,0,0,1-.58.14.69.69,0,0,1-.45-.47A.58.58,0,0,1,0,4,.68.68,0,0,1,.5,3.61L4,1.89A.41.41,0,0,1,4.27,1.76ZM32.48,18a.78.78,0,0,1-.24.59.9.9,0,0,1-.62.23H7.68a.81.81,0,0,1-.6-.23A.8.8,0,0,1,6.86,18V.86A.85.85,0,0,1,7.08.23.81.81,0,0,1,7.68,0H31.62a.9.9,0,0,1,.62.23.82.82,0,0,1,.24.63ZM30.76,1.72H8.54V17.1H30.76Zm-15.15,19a.85.85,0,0,1,.62-.23.9.9,0,0,1,.63.23.8.8,0,0,1,.25.61.9.9,0,0,1-.25.64.87.87,0,0,1-.63.24.81.81,0,0,1-.62-.24.9.9,0,0,1-.23-.62A.85.85,0,0,1,15.61,20.73Zm8-11.67a.85.85,0,0,1,.23.37.31.31,0,0,1-.06.33l-.17.16-5.45,3.41a.31.31,0,0,1-.33.06.92.92,0,0,1-.37-.23V6a.31.31,0,0,1,.13-.23.55.55,0,0,1,.37-.1h.2Zm-3.3,11.67a.82.82,0,0,1,.23.61.93.93,0,0,1-.23.64.79.79,0,0,1-.61.24.83.83,0,0,1-.88-.88.79.79,0,0,1,.24-.61.9.9,0,0,1,.62-.23A.85.85,0,0,1,20.28,20.73Zm3.41,0a.82.82,0,0,1,.22.61.92.92,0,0,1-.22.64.81.81,0,0,1-.62.24.79.79,0,0,1-.61-.24.93.93,0,0,1-.23-.64.82.82,0,0,1,.23-.61.81.81,0,0,1,.59-.23A.88.88,0,0,1,23.69,20.73Zm12.19-5.68,2-1a.49.49,0,0,1,.58-.16.72.72,0,0,1,.45.49c.3.52.19.86-.33,1l-3.77,1.88-.53-.16c-.08,0-.16-.08-.23-.23a1.1,1.1,0,0,1-.1-.47V2.75a1,1,0,0,1,.33-.7,1.25,1.25,0,0,1,.45-.12,1,1,0,0,1,.41.12l3.44,1.72c.52.17.63.51.33,1a.7.7,0,0,1-.39.47.71.71,0,0,1-.64-.14l-2-1Z" />
      </g>
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const newsholder_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.newsholder-cls-1,.newsholder-cls-2,.newsholder-cls-3{fill:none;}.newsholder-cls-2,.newsholder-cls-3{stroke-miterlimit:10;}.newsholder-cls-3{stroke-width:2px;}.newsholder-cls-4{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="newsholder-cls-3" x="1" y="1" width="63.57" height="62.61" />
      <rect
        class="newsholder-cls-2"
        x="14.86"
        y="17.14"
        width="15.62"
        height="20.94"
      />
      <polygon
        class="newsholder-cls-4"
        points="14.51 37.66 18.18 27.94 21.85 30.66 24.63 24.54 30.27 37.66 14.51 37.66"
      />
      <circle class="newsholder-cls-4" cx="18.35" cy="22.2" r="1.67" />
      <rect
        class="newsholder-cls-2"
        x="35.44"
        y="17.14"
        width="15.62"
        height="20.94"
      />
      <polygon
        class="newsholder-cls-4"
        points="35.14 37.66 38.8 27.95 42.47 30.67 45.26 24.55 50.9 37.66 35.14 37.66"
      />
      <circle class="newsholder-cls-4" cx="38.98" cy="22.21" r="1.67" />
      <line
        class="newsholder-cls-1"
        x1="35.14"
        y1="42.35"
        x2="50.1"
        y2="42.35"
      />
      <line
        class="newsholder-cls-1"
        x1="35.14"
        y1="47.47"
        x2="42.66"
        y2="47.47"
      />
      <line
        class="newsholder-cls-1"
        x1="14.51"
        y1="42.35"
        x2="29.48"
        y2="42.35"
      />
      <line
        class="newsholder-cls-1"
        x1="14.51"
        y1="47.47"
        x2="22.03"
        y2="47.47"
      />
      <polygon
        class="newsholder-cls-4"
        points="11.41 30.22 10.83 29.4 6.69 32.3 10.85 35.21 11.42 34.39 8.45 32.29 11.41 30.22"
      />
      <polygon
        class="newsholder-cls-4"
        points="54.2 30.22 54.78 29.4 58.92 32.3 54.76 35.21 54.19 34.39 57.16 32.29 54.2 30.22"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const spacer_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.spacer-cls-1,.spacer-cls-2{fill:none;stroke-miterlimit:10;}.spacer-cls-2{stroke-width:2px;}.spacer-cls-3{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="spacer-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <line class="spacer-cls-1" x1="12.37" y1="16.56" x2="53.77" y2="16.56" />
      <line class="spacer-cls-1" x1="12.37" y1="48.02" x2="53.77" y2="48.02" />
      <polygon
        class="spacer-cls-3"
        points="31.65 30.98 31.65 36.43 31.65 37 29.14 37 33.08 43.83 37.03 37 34.51 37 34.51 36.43 34.51 30.98 31.65 30.98"
      />
      <polygon
        class="spacer-cls-3"
        points="34.49 34.05 34.49 28.6 34.49 28.02 37.01 28.02 33.06 21.19 29.11 28.02 31.63 28.02 31.63 28.6 31.63 34.05 34.49 34.05"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const textfield_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.textfield-cls-1,.textfield-cls-2{fill:none;}.textfield-cls-2{stroke-miterlimit:10;stroke-width:2px;}`}</style>
    </defs>
    <g>
      <rect class="textfield-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <line
        class="textfield-cls-1"
        x1="15.23"
        y1="17.01"
        x2="50.35"
        y2="17.01"
      />
      <line
        class="textfield-cls-1"
        x1="15.23"
        y1="23.13"
        x2="32.87"
        y2="23.13"
      />
      <line
        class="textfield-cls-1"
        x1="15.23"
        y1="29.24"
        x2="50.35"
        y2="29.24"
      />
      <line
        class="textfield-cls-1"
        x1="15.23"
        y1="35.36"
        x2="32.87"
        y2="35.36"
      />
      <line
        class="textfield-cls-1"
        x1="15.23"
        y1="41.48"
        x2="50.35"
        y2="41.48"
      />
      <line class="textfield-cls-1" x1="15.23" y1="47.6" x2="32.87" y2="47.6" />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const textwithimage_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.textwithimage-cls-1,.textwithimage-cls-2,.textwithimage-cls-3{fill:none;}.textwithimage-cls-2,.textwithimage-cls-3{stroke-miterlimit:10;}.textwithimage-cls-3{stroke-width:2px;}.textwithimage-cls-4{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect
        class="textwithimage-cls-3"
        x="1"
        y="1"
        width="63.57"
        height="62.61"
      />
      <rect
        class="textwithimage-cls-2"
        x="14.58"
        y="21.3"
        width="21.26"
        height="20.94"
      />
      <polygon
        class="textwithimage-cls-4"
        points="14.58 41.72 19.4 31.67 24.22 34.48 27.88 28.15 35.29 41.72 14.58 41.72"
      />
      <circle class="textwithimage-cls-4" cx="18.39" cy="25.32" r="2.02" />
      <line
        class="textwithimage-cls-1"
        x1="38.22"
        y1="30.19"
        x2="53.18"
        y2="30.19"
      />
      <line
        class="textwithimage-cls-1"
        x1="38.22"
        y1="35.31"
        x2="45.73"
        y2="35.31"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const textwithheadline_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.textwithheadline-cls-1,.textwithheadline-cls-2{fill:none;}.textwithheadline-cls-1,.textwithheadline-cls-2,.textwithheadline-cls-2{stroke-width:2px;}.textwithheadline-cls-2,.textwithheadline-cls-3{stroke-miterlimit:10;}`}</style>
    </defs>
    <g>
      <rect
        class="textwithheadline-cls-2"
        x="1"
        y="1"
        width="63.57"
        height="62.61"
      />
      <path
        class="textwithheadline-cls-3"
        d="m33.75,18.93l.26,5.59h-.67c-.13-.98-.3-1.69-.53-2.11-.36-.68-.85-1.18-1.45-1.5s-1.4-.48-2.38-.48h-3.36v18.21c0,1.46.16,2.38.47,2.74.45.49,1.13.74,2.06.74h.83v.65h-10.11v-.65h.84c1.01,0,1.72-.3,2.14-.91.26-.38.39-1.23.39-2.57v-18.21h-2.87c-1.11,0-1.9.08-2.37.25-.61.22-1.13.65-1.56,1.28s-.69,1.49-.77,2.57h-.67l.28-5.59h19.46Z"
      />
      <line
        class="textwithheadline-cls-1"
        x1="37.6"
        y1="30.74"
        x2="52.57"
        y2="30.74"
      />
      <line
        class="textwithheadline-cls-1"
        x1="37.6"
        y1="35.86"
        x2="45.12"
        y2="35.86"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const title_h1_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.title-h1-cls-1{fill:none;stroke-width:2px;}.title-h1-cls-1,.title-h1-cls-2{stroke-miterlimit:10;}.title-h1-cls-3{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="title-h1-cls-1" x="1" y="1" width="63.57" height="62.61" />
      <path
        class="title-h1-cls-2"
        d="m34.21,18.93l.26,5.59h-.67c-.13-.98-.3-1.69-.53-2.11-.36-.68-.85-1.18-1.45-1.5s-1.4-.48-2.38-.48h-3.36v18.21c0,1.46.16,2.38.47,2.74.45.49,1.13.74,2.06.74h.83v.65h-10.11v-.65h.84c1.01,0,1.72-.3,2.14-.91.26-.38.39-1.23.39-2.57v-18.21h-2.87c-1.11,0-1.9.08-2.37.25-.61.22-1.13.65-1.56,1.28s-.69,1.49-.77,2.57h-.67l.28-5.59h19.46Z"
      />
      <path
        class="title-h1-cls-3"
        d="m45.15,49c0,.05-.01.1-.04.14s-.07.07-.14.09-.15.04-.26.06-.24.02-.4.02c-.17,0-.31,0-.41-.02s-.19-.04-.25-.06-.11-.05-.14-.09-.04-.08-.04-.14v-5.61h-5.75v5.61c0,.05-.01.1-.04.14s-.07.07-.14.09-.15.04-.26.06-.24.02-.41.02-.29,0-.4-.02-.2-.04-.26-.06-.11-.05-.14-.09-.04-.08-.04-.14v-12.13c0-.05.01-.1.04-.14s.07-.07.14-.09.15-.04.26-.06.24-.02.4-.02.3,0,.41.02.19.04.26.06.11.05.14.09.04.08.04.14v5.06h5.75v-5.06c0-.05.01-.1.04-.14s.07-.07.14-.09.15-.04.25-.06.24-.02.41-.02c.16,0,.29,0,.4.02s.19.04.26.06.11.05.14.09.04.08.04.14v12.13Z"
      />
      <path
        class="title-h1-cls-3"
        d="m55.88,48.59c0,.12,0,.23-.03.31s-.05.15-.08.21-.07.09-.11.11-.09.03-.13.03h-6.6s-.09-.01-.13-.03-.08-.06-.11-.11-.06-.12-.08-.21-.03-.19-.03-.31c0-.12,0-.22.03-.3s.04-.15.07-.21.07-.1.11-.13.09-.04.14-.04h2.64v-9.61l-2.44,1.46c-.12.07-.22.1-.3.12s-.13,0-.18-.04-.08-.11-.09-.21-.02-.22-.02-.37c0-.11,0-.21.01-.28s.02-.14.04-.2.05-.1.08-.14.08-.08.14-.12l2.91-1.87s.06-.04.1-.05.09-.03.15-.04.13-.02.21-.02.18,0,.29,0c.16,0,.29,0,.39.02s.19.03.24.05.1.05.12.09.03.07.03.11v11.1h2.29c.05,0,.1.01.15.04s.08.07.11.13.05.12.07.21.02.19.02.3Z"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const title_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.title-cls-1{fill:none;stroke-width:2px;}.title-cls-1,.title-cls-2{stroke-miterlimit:10;}`}</style>
    </defs>
    <g>
      <rect class="title-cls-1" x="1" y="1" width="63.57" height="62.61" />
      <path
        class="title-cls-2"
        d="m42.53,20.39l.26,5.59h-.67c-.13-.98-.3-1.69-.53-2.11-.36-.68-.85-1.18-1.45-1.5s-1.4-.48-2.38-.48h-3.36v18.21c0,1.46.16,2.38.47,2.74.45.49,1.13.74,2.06.74h.83v.65h-10.11v-.65h.84c1.01,0,1.72-.3,2.14-.91.26-.38.39-1.23.39-2.57v-18.21h-2.87c-1.11,0-1.9.08-2.37.25-.61.22-1.13.65-1.56,1.28s-.69,1.49-.77,2.57h-.67l.28-5.59h19.46Z"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const accordeon_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    viewBox="0 0 65.57 64.61"
    style={style ? style : {}}
  >
    <defs>
      <style>{`.accordeon-cls-1,.accordeon-cls-2,.accordeon-cls-3{fill:none;}.accordeon-cls-2,.accordeon-cls-3{stroke-miterlimit:10;}.accordeon-cls-3{stroke-width:2px;}.accordeon-cls-4{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="accordeon-cls-3" x="1" y="1" width="63.57" height="62.61" />
      <rect
        class="accordeon-cls-2"
        x="1"
        y="36.45"
        width="63.57"
        height="9.07"
      />
      <rect
        class="accordeon-cls-2"
        x="1"
        y="45.52"
        width="63.57"
        height="9.07"
      />
      <rect
        class="accordeon-cls-2"
        x="1"
        y="54.59"
        width="63.57"
        height="9.07"
      />
      <line
        class="accordeon-cls-1"
        x1="6.93"
        y1="41.06"
        x2="31.55"
        y2="41.06"
      />
      <line
        class="accordeon-cls-1"
        x1="6.93"
        y1="50.21"
        x2="31.55"
        y2="50.21"
      />
      <line
        class="accordeon-cls-1"
        x1="6.93"
        y1="59.11"
        x2="31.55"
        y2="59.11"
      />
      <line
        class="accordeon-cls-1"
        x1="6.93"
        y1="17.66"
        x2="31.55"
        y2="17.66"
      />
      <line
        class="accordeon-cls-1"
        x1="6.93"
        y1="22.98"
        x2="19.29"
        y2="22.98"
      />
      <polygon
        class="accordeon-cls-4"
        points="60.86 56.77 61.68 57.35 58.78 61.49 55.88 57.33 56.7 56.76 58.79 59.73 60.86 56.77"
      />
      <polygon
        class="accordeon-cls-4"
        points="60.86 47.74 61.68 48.32 58.78 52.46 55.88 48.3 56.7 47.73 58.79 50.7 60.86 47.74"
      />
      <polygon
        class="accordeon-cls-4"
        points="60.86 38.71 61.68 39.29 58.78 43.43 55.88 39.27 56.7 38.69 58.79 41.67 60.86 38.71"
      />
      <path
        class="accordeon-cls-4"
        d="m64.57,10.07H1V1h63.57v9.07Zm-5.81-5.14l2.09,2.97.82-.57-2.9-4.16-2.9,4.14.82.58,2.07-2.96Zm-27.22.14H6.93v1.04h24.62v-1.04Z"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const multimedia_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.multimedia-cls-1{fill:none;stroke-miterlimit:10;stroke-width:2px;}.multimedia-cls-2{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="multimedia-cls-1" x="1" y="1" width="63.57" height="62.61" />
      <polygon
        class="multimedia-cls-2"
        points="21.96 17.75 21.96 46.86 49.74 32.3 21.96 17.75"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const imageticker_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    width={width}
    height={height}
    style={style ? style : {}}
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.imageticker-cls-1,.imageticker-cls-2{fill:none;stroke-miterlimit:10;}.imageticker-cls-3,.imageticker-cls-4,.imageticker-cls-5{stroke-width:0px;}.imageticker-cls-4{fill:#fff;}.imageticker-cls-2{stroke-width:2px;}.imageticker-cls-6{mask:url(#mask-ticker-1);}.imageticker-cls-7{mask:url(#mask-ticker);}`}</style>
      <mask
        id="mask-ticker"
        x="36.01"
        y="18.23"
        width="17.1"
        height="27.57"
        maskUnits="userSpaceOnUse"
      >
        <rect
          class="imageticker-cls-4"
          x="44.52"
          y="18.23"
          width="8.51"
          height="27.57"
        />
      </mask>
      <mask
        id="mask-ticker-1"
        x="4.62"
        y="18.36"
        width="17.02"
        height="27.57"
        maskUnits="userSpaceOnUse"
      >
        <rect
          class="imageticker-cls-4"
          x="13.13"
          y="18.36"
          width="8.51"
          height="27.57"
        />
      </mask>
    </defs>
    <g>
      <rect
        class="imageticker-cls-2"
        x="1"
        y="1"
        width="63.57"
        height="62.61"
      />
      <rect
        class="imageticker-cls-1"
        x="25.32"
        y="22.1"
        width="15.62"
        height="20.94"
      />
      <polygon
        class="imageticker-cls-5"
        points="24.97 42.72 28.64 32.93 32.31 35.67 35.1 29.51 40.73 42.72 24.97 42.72"
      />
      <circle class="imageticker-cls-5" cx="28.81" cy="27.17" r="1.67" />
      <g class="imageticker-cls-7">
        <rect
          class="imageticker-cls-1"
          x="45.5"
          y="22.1"
          width="15.62"
          height="20.94"
        />
        <polygon
          class="imageticker-cls-5"
          points="45.2 42.72 48.86 32.94 52.53 35.68 55.32 29.52 60.96 42.72 45.2 42.72"
        />
        <circle class="imageticker-cls-5" cx="49.04" cy="27.18" r="1.67" />
      </g>
      <g class="imageticker-cls-6">
        <rect
          class="imageticker-cls-1"
          x="5.12"
          y="22.1"
          width="15.62"
          height="20.94"
        />
        <polygon
          class="imageticker-cls-5"
          points="4.77 42.72 8.44 32.93 12.11 35.67 14.89 29.51 20.53 42.72 4.77 42.72"
        />
        <circle class="imageticker-cls-3" cx="8.61" cy="27.17" r="1.67" />
      </g>
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the add-content-modal for the component selection
 */
export const login_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    width={width}
    height={height}
    style={style ? style : {}}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.login-cls-1,.login-cls-2{fill:none;stroke-miterlimit:10;}.login-cls-2{stroke-width:2px;}`}</style>
    </defs>
    <g>
      <rect class="login-cls-2" x="1" y="1" width="63.57" height="62.61" />
      <polygon
        class="login-cls-1"
        points="19.49 24.67 35.77 32.26 19.75 39.9 19.66 34.57 17.21 34.65 17.15 30.09 19.58 30.03 19.49 24.67"
      />
      <polyline
        class="login-cls-2"
        points="31.1 18.8 46.9 18.8 46.9 46.01 31.58 46.01"
      />
    </g>
  </svg>
);

/*************************************************************************************
 *************************************************************************************
 * OTHER CUSTOM ICONS:
 *************************************************************************************
 *************************************************************************************
 */

/*
 * Add new card button icon
 */
export const add_cards_plus_icon = (classNames, width, height, style) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={classNames}
    width={width}
    height={height}
    viewBox="0 0 1027.4 1538"
    style={style ? style : {}}
  >
    <g>
      <path
        fill="#438AC9"
        d="M490.9,845.2c0-15.3,0-30.7,0-46c0-8.4,1-7.5-7.2-7.5c-31.1,0-62.2,0-93.4,0c-7,0-13.6-1.2-18.9-6.3   c-7-6.7-9-14.8-6.2-23.9c2.8-9.1,9.3-14.4,18.9-15.7c2.5-0.4,5.1-0.5,7.7-0.5c31.3,0,62.6-0.1,93.9,0.1c4.3,0,5.4-1.2,5.4-5.4   c-0.2-31.5-0.1-63.1-0.1-94.6c0-7.8,1.5-14.9,7.5-20.4c6.8-6.2,14.8-7.7,23.4-4.8c8.6,2.9,13.8,9.2,15.1,18.3   c0.4,2.7,0.5,5.4,0.5,8.1c0,30.7,0,61.4,0,92.1c0,6.7,0,6.7,6.5,6.7c31.5,0,62.9,0,94.4,0c12.9,0,21.3,5.5,24.8,15.9   c4.6,13.7-4.2,28.1-18.6,30c-2.5,0.3-5.1,0.5-7.7,0.5c-31.3,0-62.6,0.1-93.9-0.1c-4.5,0-5.7,1.2-5.7,5.7   c0.2,31.5,0.1,63.1,0.1,94.6c0,7.2-1.3,13.9-6.6,19.3c-6.8,6.8-15,8.7-24,5.8c-8.9-2.9-14.1-9.3-15.4-18.6   c-0.3-2.4-0.5-4.7-0.5-7.1C490.9,875.9,490.9,860.6,490.9,845.2z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,345.4c0,5.8-0.2,11.6,0.1,17.4c0.2,4.4-2.7,3.5-5.1,3.4c-2.2-0.1-5.3,1.3-5.3-3.2   c0.2-11.8,0.2-23.5,0-35.3c-0.1-4.9,3.3-3.2,5.6-3.4c2.6-0.2,5.1-0.5,4.8,3.7C1017.8,333.8,1018.1,339.6,1018.1,345.4z"
      />
      <path
        fill="#448BCA"
        d="M21.7,971.4c0,5.8-0.2,11.6,0.1,17.3c0.2,3.8-2.1,3.7-4.6,3.4c-2.3-0.3-5.8,1.5-5.8-3.3   c0.1-11.7,0.2-23.4,0-35.1c-0.1-4.3,2.8-3.5,5.1-3.4c2.3,0,5.4-1.1,5.2,3.3C21.6,959.6,21.7,965.5,21.7,971.4z"
      />
      <path
        fill="#438BC9"
        d="M1007.5,38c0-5.3-0.1-10.6,0-15.8c0.1-4.2,4.1-5.3,6.8-6.6c2-1,2.6,2.5,3.1,4.3c1.4,5.2,0.5,10.5,0.7,15.8   c0.2,6.1-0.3,12.3,0.1,18.4c0.3,4.6-2.3,4.2-5.3,4.3c-3.4,0.1-5.5-0.2-5.1-4.5c0.4-5.3,0.1-10.6,0.1-15.8   C1007.7,38,1007.6,38,1007.5,38z"
      />
      <path
        fill="#448BCA"
        d="M47.5,18.7c-5.6,0-11.3-0.2-16.9,0.1c-4.1,0.2-3.8-2.3-3.7-4.9c0.2-2.4-1.2-5.6,3.6-5.5   c11.3,0.2,22.5,0.3,33.8,0c4.3-0.1,3.8,2.4,3.6,4.9c-0.1,2.4,1.3,5.7-3.5,5.5C58.7,18.5,53.1,18.7,47.5,18.7z"
      />
      <path
        fill="#448BCA"
        d="M109,18.7c-5.6,0-11.3-0.2-16.9,0.1c-4.1,0.2-3.8-2.3-3.7-4.9c0.2-2.4-1.2-5.6,3.6-5.5   c11.3,0.2,22.5,0.3,33.8,0c4.3-0.1,3.8,2.4,3.6,4.9c-0.1,2.4,1.3,5.7-3.5,5.5C120.3,18.5,114.7,18.7,109,18.7z"
      />
      <path
        fill="#448BCA"
        d="M170.1,18.7c-5.6,0-11.3-0.1-16.9,0c-4.1,0.1-3.3-2.6-3.1-4.8c0.2-2.1-1.6-5.5,3-5.5c11.6,0.1,23.2,0.1,34.8,0   c5,0,2.8,3.6,3.1,5.8c0.4,2.5,0.2,4.6-3.5,4.5C181.7,18.5,175.9,18.7,170.1,18.7z"
      />
      <path
        fill="#448BCA"
        d="M232,18.7c-5.5,0-10.9-0.3-16.4,0.1c-4.3,0.3-4.2-2.1-4.1-5c0.1-2.6-0.9-5.5,3.8-5.3c11.3,0.3,22.5,0.2,33.8,0   c4.8-0.1,3.3,3.2,3.5,5.6c0.2,2.6,0.5,5.1-3.7,4.8C243.3,18.5,237.7,18.7,232,18.7z"
      />
      <path
        fill="#448BCA"
        d="M293.8,18.7c-5.8,0-11.6-0.1-17.4,0c-4.1,0.1-3.3-2.6-3.1-4.8c0.2-2.1-1.6-5.5,3-5.5c11.6,0.1,23.2,0.1,34.8,0   c5,0,2.8,3.6,3.1,5.9c0.4,2.5,0.2,4.7-3.5,4.5C305.1,18.5,299.4,18.7,293.8,18.7z"
      />
      <path
        fill="#448BCA"
        d="M355.3,18.7c-5.8,0-11.6-0.1-17.4,0c-4.1,0.1-3.3-2.6-3.1-4.8c0.2-2.1-1.6-5.5,3-5.5c11.6,0.1,23.2,0.1,34.8,0   c5,0,2.8,3.6,3.1,5.9c0.4,2.5,0.2,4.7-3.5,4.5C366.6,18.5,361,18.7,355.3,18.7z"
      />
      <path
        fill="#448BCA"
        d="M417.2,8.5c5.5,0,10.9,0.3,16.4-0.1c4.2-0.3,4,2.2,3.7,4.8c-0.2,2.4,1.4,5.7-3.4,5.6   c-11.4-0.2-22.9-0.1-34.3,0c-4.9,0.1-2.9-3.5-3.2-5.8c-0.3-2.5-0.4-4.7,3.4-4.6C405.6,8.7,411.4,8.5,417.2,8.5z"
      />
      <path
        fill="#448BCA"
        d="M478.7,18.7c-5.8,0-11.6-0.2-17.4,0.1c-4.4,0.2-3.4-2.8-3.4-5.1c0-2.3-1.2-5.3,3.3-5.2   c11.4,0.2,22.9,0.2,34.3,0c4.4-0.1,3.4,2.8,3.4,5.1c0,2.3,1.2,5.4-3.3,5.2C490,18.5,484.4,18.7,478.7,18.7z"
      />
      <path
        fill="#448BCA"
        d="M540.4,8.5c5.5,0,10.9,0.3,16.4-0.1c4.2-0.3,3.9,2.2,3.7,4.8c-0.2,2.4,1.4,5.6-3.5,5.5   c-11.3-0.2-22.5-0.2-33.8,0c-4.6,0.1-3.6-2.7-3.8-5.3c-0.1-2.9-0.3-5.3,4-5.1C529.1,8.8,534.7,8.5,540.4,8.5z"
      />
      <path
        fill="#448BCA"
        d="M601.4,18.7c-5.6,0-11.3-0.2-16.9,0.1c-4.4,0.2-3.5-2.7-3.4-5.1c0.1-2.3-1.2-5.3,3.2-5.3   c11.4,0.2,22.9,0.2,34.3,0c4.9-0.1,3.2,3.3,3.4,5.6c0.3,2.7,0.3,5-3.8,4.8C612.7,18.5,607,18.7,601.4,18.7z"
      />
      <path
        fill="#448BCA"
        d="M663,18.7c-5.6,0-11.3-0.2-16.9,0.1c-4.4,0.2-3.5-2.7-3.4-5.1c0.1-2.3-1.2-5.3,3.2-5.3   c11.4,0.2,22.9,0.2,34.3,0c4.9-0.1,3.2,3.3,3.4,5.6c0.3,2.7,0.3,5-3.8,4.8C674.2,18.5,668.6,18.7,663,18.7z"
      />
      <path
        fill="#448BCA"
        d="M724.3,18.7c-5.6,0-11.3-0.1-16.9,0c-4.1,0.1-3.3-2.6-3.1-4.8c0.2-2.1-1.6-5.5,3-5.5c11.6,0.1,23.2,0.1,34.8,0   c5,0,2.8,3.6,3.1,5.8c0.4,2.5,0.2,4.6-3.5,4.5C735.9,18.5,730.1,18.7,724.3,18.7z"
      />
      <path
        fill="#448BCA"
        d="M786.7,18.7c-5.6,0-11.3-0.3-16.9,0.1c-4.3,0.3-4.2-2.1-4.1-5c0.1-2.6-0.9-5.5,3.8-5.4   c11.3,0.3,22.5,0.2,33.8,0c4.8-0.1,3.3,3.2,3.5,5.6c0.2,2.6,0.5,5.1-3.7,4.8C797.6,18.4,792.1,18.7,786.7,18.7z"
      />
      <path
        fill="#448BCA"
        d="M847.9,18.7c-5.8,0-11.6-0.1-17.4,0c-4.1,0.1-3.3-2.6-3.1-4.8c0.2-2.1-1.6-5.5,3-5.5c11.6,0.1,23.2,0.1,34.8,0   c5,0,2.8,3.6,3.1,5.9c0.4,2.5,0.2,4.7-3.5,4.5C859.2,18.5,853.5,18.7,847.9,18.7z"
      />
      <path
        fill="#448BCA"
        d="M909,18.7c-5.6,0-11.3-0.1-16.9,0c-4.1,0.1-3.3-2.6-3.1-4.8c0.2-2.1-1.6-5.5,3-5.5c11.6,0.1,23.2,0.1,34.8,0   c5,0,2.8,3.6,3.1,5.8c0.4,2.5,0.2,4.6-3.5,4.5C920.6,18.5,914.8,18.7,909,18.7z"
      />
      <path
        fill="#448BCA"
        d="M971.4,18.7c-5.6,0-11.3-0.3-16.9,0.1c-4.3,0.3-4.2-2.1-4.1-5c0.1-2.6-0.9-5.5,3.8-5.4   c11.3,0.3,22.5,0.2,33.8,0c4.8-0.1,3.3,3.2,3.5,5.6c0.2,2.6,0.5,5.1-3.7,4.8C982.3,18.4,976.9,18.7,971.4,18.7z"
      />
      <path
        fill="#438AC9"
        d="M397.3,1522.6c-5.8,0-11.6-0.2-17.4,0.1c-4.6,0.2-3.1-3-3.2-5.2c0-2.2-1.3-5.2,3.2-5.1   c11.6,0.2,23.2,0.1,34.8,0c4.7,0,2.7,3.4,2.9,5.4c0.2,2,1.3,5-2.9,4.9C408.9,1522.4,403.1,1522.6,397.3,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,99.4c0-5.6,0.3-11.3-0.1-16.9c-0.3-4.2,2.2-4,4.8-3.7c2.3,0.2,5.7-1.5,5.6,3.4   c-0.2,11.4-0.2,22.8,0,34.3c0.1,4.5-3,3.1-5.3,3.2c-2.4,0.1-5.3,1-5.1-3.4C1008,110.6,1007.8,105,1007.8,99.4z"
      />
      <path
        fill="#438AC9"
        d="M458.3,1522.6c-5.5,0-10.9-0.3-16.4,0.1c-4.3,0.3-3.8-2.3-3.6-4.8c0.2-2.3-1.6-5.7,3.4-5.6   c11.4,0.2,22.8,0.2,34.3,0c5-0.1,3.1,3.3,3.3,5.6c0.2,2.5,0.7,5.1-3.6,4.8C469.9,1522.3,464.1,1522.6,458.3,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,160.3c0,5.8-0.2,11.6,0.1,17.4c0.2,4.4-2.8,3.4-5.1,3.4c-2.2-0.1-5.3,1.3-5.2-3.2   c0.2-11.4,0.2-22.8,0-34.3c-0.1-4.4,2.8-3.4,5.1-3.3c2.2,0.1,5.4-1.3,5.2,3.2C1017.9,149,1018.1,154.7,1018.1,160.3z"
      />
      <path
        fill="#438AC9"
        d="M520.5,1522.6c-5.8,0-11.6-0.2-17.4,0.1c-4.6,0.2-3.1-3-3.2-5.2c0-2.2-1.3-5.2,3.2-5.1   c11.6,0.2,23.2,0.1,34.8,0c4.7,0,2.7,3.4,2.9,5.4c0.2,2,1.3,5-2.9,4.9C532.1,1522.4,526.3,1522.6,520.5,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,222c0,5.6-0.2,11.3,0.1,16.9c0.2,4.8-3.1,3.4-5.5,3.5c-2.6,0.1-5,0.6-4.9-3.6   c0.3-11.2,0.3-22.5,0-33.7c-0.1-4.9,3.1-3.3,5.5-3.5c2.5-0.1,5.2-0.7,4.9,3.6C1017.8,210.8,1018.1,216.4,1018.1,222z"
      />
      <path
        fill="#438AC9"
        d="M581.8,1522.6c-5.6,0-11.3-0.2-16.9,0.1c-4.9,0.3-3.3-3.2-3.4-5.5c-0.2-2.4-0.8-5,3.5-4.9   c11.2,0.3,22.5,0.3,33.7,0c4.7-0.1,3.7,2.7,3.7,5.3c0,2.7,0.7,5.5-3.8,5.1C593,1522.3,587.4,1522.6,581.8,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,283c0-5.5,0.3-10.9-0.1-16.4c-0.3-4.2,2.2-4,4.8-3.7c2.3,0.2,5.7-1.5,5.6,3.4   c-0.2,11.4-0.2,22.8,0,34.3c0.1,4.5-3,3.1-5.3,3.2c-2.4,0.1-5.3,1-5.1-3.4C1008,294.6,1007.8,288.8,1007.8,283z"
      />
      <path
        fill="#438AC9"
        d="M643.4,1522.6c-5.8,0-11.6-0.1-17.4,0c-4.2,0.1-3.1-2.9-2.9-4.9c0.1-2.1-1.8-5.5,2.9-5.4   c11.8,0.1,23.5,0.1,35.3,0c4.3,0,2.7,3.2,2.7,5.1c0,1.9,1.7,5.2-2.6,5.1C655.3,1522.5,649.4,1522.6,643.4,1522.6z"
      />
      <path
        fill="#438AC9"
        d="M705.1,1522.6c-5.6,0-11.3-0.3-16.9,0.1c-4.3,0.3-3.8-2.3-3.6-4.8c0.2-2.3-1.6-5.7,3.3-5.6   c11.4,0.2,22.8,0.2,34.3,0c5-0.1,3.1,3.3,3.3,5.6c0.2,2.5,0.7,5.1-3.6,4.8C716.4,1522.3,710.7,1522.6,705.1,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,407c0,5.8-0.2,11.6,0.1,17.4c0.2,4.5-3,3.2-5.2,3.2c-2.3,0-5.2,1.1-5.1-3.3c0.2-11.4,0.2-22.8,0-34.3   c-0.1-4.5,3-3.2,5.2-3.2c2.3,0,5.3-1.1,5.1,3.3C1017.9,395.7,1018.1,401.3,1018.1,407z"
      />
      <path
        fill="#438AC9"
        d="M767.2,1512.3c5.5,0,10.9,0.3,16.4-0.1c4.3-0.3,3.8,2.3,3.6,4.8c-0.2,2.3,1.6,5.7-3.4,5.6   c-11.4-0.2-22.8-0.2-34.3,0c-5,0.1-3.1-3.3-3.3-5.6c-0.2-2.5-0.7-5.1,3.6-4.8C755.6,1512.6,761.4,1512.3,767.2,1512.3z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,469.1c0,5.5-0.2,10.9,0.1,16.4c0.3,4.8-3,3.4-5.5,3.6c-2.6,0.1-5,0.6-4.9-3.6   c0.2-11.2,0.3-22.5,0-33.7c-0.1-4.8,3.1-3.4,5.5-3.5c2.5-0.1,5.2-0.8,4.9,3.6C1017.8,457.5,1018.1,463.3,1018.1,469.1z"
      />
      <path
        fill="#438AC9"
        d="M827.7,1522.6c-5.5,0-10.9-0.3-16.4,0.1c-4.3,0.3-3.8-2.3-3.6-4.8c0.2-2.3-1.6-5.7,3.3-5.6   c11.4,0.2,22.8,0.2,34.3,0c5-0.1,3.1,3.3,3.3,5.6c0.2,2.5,0.7,5.1-3.6,4.8C839.3,1522.3,833.5,1522.6,827.7,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,530.5c0,5.5-0.2,10.9,0.1,16.4c0.3,4.8-3,3.4-5.5,3.6c-2.6,0.1-5,0.6-4.9-3.6   c0.2-11.2,0.3-22.5,0-33.7c-0.1-4.8,3.1-3.4,5.5-3.5c2.5-0.1,5.2-0.8,4.9,3.6C1017.8,518.9,1018.1,524.7,1018.1,530.5z"
      />
      <path
        fill="#438AC9"
        d="M889.8,1522.6c-5.6,0-11.3-0.3-16.9,0.1c-4.3,0.3-3.9-2.3-3.6-4.8c0.2-2.3-1.6-5.7,3.3-5.6   c11.4,0.2,22.8,0.2,34.3,0c5-0.1,3.2,3.3,3.4,5.6c0.2,2.5,0.7,5.1-3.6,4.8C901.1,1522.3,895.4,1522.6,889.8,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,591.9c0,5.5-0.2,10.9,0.1,16.4c0.2,4.8-3.1,3.4-5.5,3.5c-2.6,0.1-5,0.6-4.9-3.6   c0.3-11.2,0.3-22.5,0-33.7c-0.1-4.9,3.1-3.3,5.5-3.5c2.5-0.1,5.2-0.7,4.9,3.6C1017.8,580.3,1018.1,586.1,1018.1,591.9z"
      />
      <path
        fill="#438AC9"
        d="M951.4,1512.3c5.6,0,11.3,0.3,16.9-0.1c4.3-0.3,3.9,2.3,3.6,4.8c-0.2,2.3,1.6,5.7-3.3,5.6   c-11.4-0.2-22.8-0.2-34.3,0c-5,0.1-3.2-3.3-3.4-5.6c-0.2-2.5-0.7-5.1,3.6-4.8C940.1,1512.6,945.7,1512.3,951.4,1512.3z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,652.9c0-5.6,0.3-11.3-0.1-16.9c-0.3-4.2,2.2-4,4.8-3.7c2.3,0.2,5.7-1.6,5.6,3.4   c-0.2,11.4-0.2,22.8,0,34.3c0.1,4.5-3,3.1-5.3,3.2c-2.4,0.1-5.3,1-5.1-3.4C1008,664.2,1007.8,658.5,1007.8,652.9z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,714.3c0,5.6-0.2,11.3,0.1,16.9c0.2,4.4-2.7,3.5-5.1,3.4c-2.2-0.1-5.3,1.3-5.3-3.2   c0.2-11.4,0.2-22.8,0-34.3c-0.1-4.9,3.3-3.1,5.6-3.4c2.6-0.3,5.1-0.4,4.8,3.7C1017.8,703,1018.1,708.7,1018.1,714.3z"
      />
      <path
        fill="#438AC9"
        d="M335.9,1522.6c-5.8,0-11.6-0.2-17.4,0.1c-4.5,0.2-3.3-2.8-3.3-5.1c0.1-2.2-1.5-5.3,3.1-5.2   c11.6,0.2,23.2,0.1,34.8,0c5.1,0,2.7,3.7,3,5.8c0.4,2.3,0.5,4.7-3.4,4.5C347.1,1522.3,341.5,1522.6,335.9,1522.6z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,775.2c0-5.6,0.2-11.3-0.1-16.9c-0.2-4.5,3-3.2,5.2-3.2c2.3,0,5.2-1.2,5.2,3.3   c-0.2,11.4-0.2,22.8,0,34.3c0.1,4.5-3,3.2-5.2,3.3c-2.3,0-5.4,1.1-5.2-3.3C1008,786.8,1007.8,781,1007.8,775.2z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,837.1c0,5.6-0.2,11.3,0.1,16.9c0.2,4.4-2.7,3.5-5.1,3.4c-2.2-0.1-5.3,1.3-5.3-3.2   c0.2-11.4,0.2-22.8,0-34.3c-0.1-4.9,3.3-3.1,5.6-3.4c2.6-0.3,5.1-0.4,4.8,3.7C1017.8,825.8,1018.1,831.4,1018.1,837.1z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,897.9c0-5.6,0.2-11.3-0.1-16.9c-0.2-4.5,3-3.1,5.3-3.2c2.3-0.1,5.2-1.1,5.1,3.3   c-0.2,11.4-0.2,22.8,0,34.3c0.1,4.5-3,3.1-5.3,3.2c-2.4,0.1-5.3,1-5.1-3.4C1008,909.5,1007.8,903.7,1007.8,897.9z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,960.1c0,5.5-0.3,10.9,0.1,16.4c0.3,4.2-2.2,3.9-4.8,3.7c-2.4-0.2-5.7,1.4-5.6-3.5   c0.2-11.2,0.3-22.5,0-33.7c-0.1-4.7,2.8-3.5,5.4-3.7c2.9-0.2,5.4-0.4,5.1,4C1017.8,948.8,1018.1,954.4,1018.1,960.1z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,1022.6c0,5.5-0.2,10.9,0.1,16.4c0.3,4.8-3,3.4-5.5,3.6c-2.6,0.1-5,0.6-4.9-3.6   c0.2-11.2,0.3-22.5,0-33.7c-0.1-4.8,3.1-3.4,5.5-3.5c2.5-0.1,5.2-0.8,4.9,3.6C1017.8,1011,1018.1,1016.8,1018.1,1022.6z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,1083.5c0,5.6-0.2,11.3,0.1,16.9c0.2,4.8-3.1,3.3-5.5,3.5c-2.6,0.2-5,0.5-4.9-3.7   c0.3-11.2,0.3-22.5,0-33.7c-0.1-4.9,3.2-3.3,5.5-3.5c2.5-0.2,5.2-0.7,4.9,3.6C1017.8,1072.3,1018.1,1077.9,1018.1,1083.5z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,1144.5c0-5.6,0.2-11.3-0.1-16.9c-0.2-4.5,3-3.1,5.2-3.2c2.3-0.1,5.2-1.1,5.1,3.3   c-0.2,11.4-0.2,22.8,0,34.3c0.1,4.5-3,3.2-5.3,3.2c-2.3,0.1-5.3,1.1-5.1-3.4C1008,1156.1,1007.8,1150.3,1007.8,1144.5z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,1206c0,5.8-0.2,11.6,0.1,17.4c0.2,4.5-2.9,3.3-5.2,3.3c-2.3,0-5.3,1.2-5.2-3.3   c0.2-11.4,0.2-22.8,0-34.3c-0.1-4.5,2.9-3.3,5.2-3.3c2.3,0,5.4-1.2,5.2,3.3C1017.9,1194.7,1018.1,1200.4,1018.1,1206z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,1267.7c0,5.6-0.2,11.3,0.1,16.9c0.2,4.8-3.1,3.3-5.5,3.5c-2.6,0.2-5,0.5-4.9-3.7   c0.3-11.2,0.3-22.5,0-33.7c-0.1-4.9,3.2-3.3,5.5-3.5c2.5-0.2,5.2-0.7,4.9,3.6C1017.8,1256.4,1018.1,1262.1,1018.1,1267.7z"
      />
      <path
        fill="#438BC9"
        d="M1007.8,1329c0-5.6,0.2-11.3-0.1-16.9c-0.3-4.9,3.1-3.3,5.5-3.5c2.5-0.2,5-0.7,4.9,3.6   c-0.3,11.2-0.3,22.5,0,33.7c0.1,4.8-3.1,3.4-5.5,3.5c-2.6,0.2-5.2,0.6-4.9-3.6C1008.1,1340.2,1007.8,1334.6,1007.8,1329z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,1390.4c0,5.6-0.2,11.3,0.1,16.9c0.3,4.8-3,3.5-5.5,3.6c-2.5,0.1-5,0.6-4.9-3.6   c0.2-11.2,0.3-22.5,0-33.7c-0.1-4.8,3-3.4,5.5-3.5c2.5-0.1,5.2-0.8,4.9,3.6C1017.8,1379.2,1018.1,1384.8,1018.1,1390.4z"
      />
      <path
        fill="#438BC9"
        d="M1018.1,1452c0,5.6-0.2,11.3,0.1,16.9c0.2,4.4-2.7,3.5-5.1,3.4c-2.2-0.1-5.3,1.3-5.3-3.2   c0.2-11.4,0.2-22.8,0-34.3c-0.1-4.9,3.3-3.1,5.6-3.4c2.6-0.3,5.1-0.5,4.8,3.7C1017.8,1440.7,1018.1,1446.3,1018.1,1452z"
      />
      <path
        fill="#438AC9"
        d="M89.4,1522.6c-5.6,0-11.3-0.3-16.9,0.1c-4.3,0.3-3.9-2.3-3.6-4.8c0.2-2.3-1.6-5.7,3.3-5.6   c11.4,0.2,22.8,0.2,34.3,0c5-0.1,3.2,3.3,3.4,5.6c0.2,2.5,0.7,5.1-3.6,4.8C100.7,1522.3,95,1522.6,89.4,1522.6z"
      />
      <path
        fill="#438AC9"
        d="M151.3,1512.3c5.6,0,11.3,0.2,16.9-0.1c4.5-0.2,3.2,2.9,3.2,5.1c0,2.2,1.4,5.3-3.2,5.2   c-11.6-0.2-23.2-0.2-34.8,0c-4.2,0.1-3.1-2.8-2.9-4.9c0.1-2.1-1.8-5.5,2.9-5.4C139.4,1512.4,145.4,1512.3,151.3,1512.3z"
      />
      <path
        fill="#438AC9"
        d="M212.7,1522.6c-5.8,0-11.6-0.2-17.4,0.1c-4.5,0.2-3.3-2.8-3.3-5.1c0.1-2.2-1.5-5.3,3.1-5.2   c11.6,0.2,23.2,0.1,34.8,0c5.1,0,2.7,3.7,3,5.8c0.4,2.3,0.5,4.7-3.4,4.5C224,1522.3,218.4,1522.6,212.7,1522.6z"
      />
      <path
        fill="#438AC9"
        d="M274.3,1522.6c-5.8,0-11.6-0.2-17.4,0.1c-4,0.2-3.6-2.3-3.3-4.6c0.3-2.2-2-5.8,3.1-5.8   c11.6,0.1,23.2,0.1,34.8,0c5,0,2.7,3.6,3,5.8c0.3,2.3,0.6,4.8-3.3,4.5C285.6,1522.3,280,1522.6,274.3,1522.6z"
      />
      <path
        fill="#448BCA"
        d="M21.8,49.2c0,5.4-0.2,10.9,0.1,16.3c0.2,4-2,4.1-4.7,3.8c-2.4-0.2-5.7,1.2-5.6-3.5c0.2-11.4,0.2-22.7,0-34.1   c-0.1-4.4,3-3.2,5.3-3.2c2.4-0.1,5.3-0.8,5.1,3.4C21.6,37.7,21.7,43.5,21.8,49.2z"
      />
      <path
        fill="#448BCA"
        d="M21.8,110.3c0,5.6-0.2,11.2,0.1,16.8c0.2,4.2-2.4,3.7-5,3.6c-2.6-0.1-5.5,0.9-5.4-3.7   c0.2-11.2,0.2-22.4,0-33.6c-0.1-4.7,3.1-3.4,5.5-3.6c2.6-0.1,5.1-0.5,4.9,3.7C21.5,99.1,21.8,104.7,21.8,110.3z"
      />
      <path
        fill="#448BCA"
        d="M11.5,172c0-5.8,0.2-11.6-0.1-17.3c-0.2-4.3,2.7-3.5,5.1-3.5c2.3,0.1,5.3-1.2,5.3,3.2   c-0.2,11.4-0.2,22.7,0,34.1c0.1,4.3-2.6,3.5-5,3.5c-2.7,0-5.6,0.7-5.4-3.8C11.7,182.9,11.5,177.5,11.5,172z"
      />
      <path
        fill="#448BCA"
        d="M11.5,233.4c0-5.6,0.3-11.2-0.1-16.8c-0.3-4.3,2.2-4.1,5.1-4c2.6,0.1,5.4-0.8,5.3,3.8c-0.3,11-0.3,22.1,0,33.1   c0.1,4.2-2.1,4.1-5.1,4.1c-3.1,0-5.7,0.1-5.4-4.4C11.8,244,11.5,238.7,11.5,233.4z"
      />
      <path
        fill="#448BCA"
        d="M21.7,294.4c0,5.8-0.1,11.5,0.1,17.3c0.1,4-2.6,3.3-4.8,3.2c-2.2-0.1-5.5,1.4-5.5-3.1c0-11.5,0.1-23.1,0-34.6   c0-4,2.7-3.3,4.9-3.1c2.2,0.1,5.5-1.5,5.4,3C21.7,282.9,21.7,288.6,21.7,294.4z"
      />
      <path
        fill="#448BCA"
        d="M21.8,357.2c0,5.4-0.2,10.9,0.1,16.3c0.2,4-2,4-4.8,3.8c-2.4-0.2-5.7,1.2-5.6-3.5c0.2-11.4,0.1-22.7,0-34.1   c0-4.9,3.5-2.9,5.8-3.2c2.5-0.3,4.7-0.3,4.5,3.5C21.6,345.7,21.7,351.5,21.8,357.2z"
      />
      <path
        fill="#448BCA"
        d="M11.5,418.1c0-5.6,0.2-11.2-0.1-16.8c-0.2-4.3,2.7-3.5,5.1-3.4c2.3,0.1,5.3-1.2,5.2,3.3   c-0.2,11.4-0.2,22.7,0,34.1c0.1,4.3-2.6,3.5-5.1,3.5c-2.7,0-5.6,0.7-5.3-3.8C11.7,429.3,11.5,423.7,11.5,418.1z"
      />
      <path
        fill="#448BCA"
        d="M11.5,480c0-5.8,0.2-11.6-0.1-17.3c-0.2-4.3,2.7-3.5,5.1-3.5c2.3,0.1,5.3-1.2,5.3,3.2   c-0.2,11.4-0.2,22.7,0,34.1c0.1,4.3-2.6,3.5-5,3.5c-2.7,0-5.6,0.7-5.4-3.8C11.7,490.9,11.5,485.4,11.5,480z"
      />
      <path
        fill="#448BCA"
        d="M11.5,541.4c0-5.8,0.2-11.5-0.1-17.3c-0.2-4.8,3.2-3.3,5.6-3.5c2.7-0.2,4.9-0.3,4.8,3.8   c-0.3,11.2-0.2,22.4,0,33.6c0.1,4.3-2.6,3.5-5,3.5c-2.6,0-5.6,0.7-5.4-3.8C11.7,552.3,11.5,546.8,11.5,541.4z"
      />
      <path
        fill="#448BCA"
        d="M21.8,602.3c0,5.6-0.2,11.2,0.1,16.8c0.2,4-2,4-4.8,3.8c-2.4-0.2-5.7,1.2-5.6-3.5c0.2-11.4,0.1-22.7,0-34.1   c0-4.9,3.5-2.9,5.8-3.2c2.5-0.3,4.7-0.3,4.5,3.5C21.6,591.1,21.7,596.7,21.8,602.3z"
      />
      <path
        fill="#448BCA"
        d="M11.5,847.8c0-5.6,0.2-11.2-0.1-16.8c-0.2-4.3,2.7-3.5,5.1-3.4c2.3,0.1,5.3-1.2,5.2,3.3   c-0.2,11.4-0.2,22.7,0,34.1c0.1,4.3-2.6,3.5-5.1,3.5c-2.7,0-5.6,0.7-5.3-3.8C11.7,859,11.5,853.4,11.5,847.8z"
      />
      <path
        fill="#448BCA"
        d="M11.5,1156.3c0-5.6,0.3-11.2-0.1-16.8c-0.3-4.3,2.2-4.1,5.1-4c2.6,0.1,5.4-0.8,5.3,3.8   c-0.3,11-0.3,22.1,0,33.1c0.1,4.2-2.1,4.1-5.1,4.1c-3.1,0-5.7,0.1-5.4-4.4C11.8,1166.8,11.5,1161.6,11.5,1156.3z"
      />
      <path
        fill="#448BCA"
        d="M11.5,664.2c0-5.6,0.3-11.2-0.1-16.8c-0.3-4.3,2.2-4.1,5.1-4c2.6,0.1,5.4-0.8,5.3,3.8c-0.3,11-0.3,22.1,0,33.1   c0.1,4.2-2.1,4.1-5.1,4.1c-3.1,0-5.7,0.1-5.4-4.4C11.8,674.7,11.5,669.4,11.5,664.2z"
      />
      <path
        fill="#448BCA"
        d="M21.8,1217.7c0,5.4-0.2,10.9,0.1,16.3c0.2,4-2,4.1-4.7,3.8c-2.4-0.2-5.7,1.3-5.7-3.5c0.2-11.4,0.2-22.7,0-34.1   c-0.1-4.4,3-3.2,5.3-3.3c2.4-0.1,5.3-0.9,5.1,3.4C21.6,1206.1,21.7,1211.9,21.8,1217.7z"
      />
      <path
        fill="#448BCA"
        d="M21.8,725.2c0,5.6-0.2,11.2,0.1,16.8c0.2,4.2-2.4,3.7-4.9,3.6c-2.6-0.1-5.6,0.9-5.5-3.7   c0.2-11.2,0.2-22.4,0-33.6c-0.1-4.7,3-3.5,5.5-3.6c2.6-0.1,5.2-0.6,4.9,3.7C21.5,714,21.8,719.6,21.8,725.2z"
      />
      <path
        fill="#448BCA"
        d="M11.5,786.9c0-5.8,0.2-11.6-0.1-17.3c-0.2-4.3,2.7-3.5,5.1-3.4c2.3,0.1,5.3-1.1,5.2,3.3   c-0.2,11.4-0.2,22.7,0,34.1c0.1,3.8-2.1,3.7-4.6,3.4c-2.3-0.3-5.9,1.5-5.8-3.3C11.6,798.1,11.5,792.5,11.5,786.9z"
      />
      <path
        fill="#448BCA"
        d="M21.7,1278.6c0,5.8-0.2,11.5,0.1,17.3c0.1,3.5-2,3.5-4.3,3.2c-2.3-0.3-6,1.8-6-3c0-11.5,0-23.1,0-34.6   c0-4.9,3.6-2.9,5.8-3.2c2.5-0.4,4.7-0.2,4.5,3.5C21.6,1267.4,21.7,1273,21.7,1278.6z"
      />
      <path
        fill="#448BCA"
        d="M21.7,1033.4c0,5.6-0.2,11.2,0.1,16.8c0.2,3.8-2.2,3.6-4.6,3.4c-2.4-0.2-5.8,1.4-5.7-3.4   c0.1-11.4,0.2-22.7,0-34.1c-0.1-4.3,2.8-3.4,5.2-3.4c2.3,0,5.3-1,5.2,3.3C21.6,1021.8,21.7,1027.6,21.7,1033.4z"
      />
      <path
        fill="#448BCA"
        d="M11.5,1340.5c0-5.8,0.2-11.6-0.1-17.3c-0.2-4.3,2.7-3.5,5.1-3.4c2.3,0.1,5.3-1.2,5.3,3.2   c-0.2,11.4-0.2,22.7,0,34.1c0.1,4.3-2.6,3.5-5,3.5c-2.7,0-5.6,0.7-5.4-3.8C11.7,1351.3,11.5,1345.9,11.5,1340.5z"
      />
      <path
        fill="#448BCA"
        d="M21.8,909.2c0,5.6-0.2,11.2,0.1,16.8c0.2,4-2,4.1-4.7,3.8c-2.4-0.2-5.7,1.2-5.6-3.5c0.2-11.4,0.2-22.7,0-34.1   c-0.1-4.4,3-3.2,5.3-3.2c2.4-0.1,5.3-0.8,5.1,3.4C21.6,898,21.7,903.6,21.8,909.2z"
      />
      <path
        fill="#448BCA"
        d="M21.8,1401.5c0,5.6-0.2,11.2,0.1,16.8c0.2,4.2-2.4,3.7-4.9,3.6c-2.6-0.1-5.6,0.9-5.5-3.7   c0.2-11.2,0.2-22.4,0-33.6c-0.1-4.7,3-3.5,5.5-3.6c2.6-0.1,5.2-0.6,4.9,3.7C21.5,1390.3,21.8,1395.9,21.8,1401.5z"
      />
      <path
        fill="#448BCA"
        d="M21.7,1094.8c0,5.6-0.2,11.2,0.1,16.8c0.2,3.8-2.2,3.6-4.6,3.4c-2.4-0.2-5.8,1.4-5.7-3.4   c0.1-11.4,0.2-22.7,0-34.1c-0.1-4.3,2.8-3.4,5.2-3.4c2.3,0,5.3-1,5.2,3.3C21.6,1083.2,21.7,1089,21.7,1094.8z"
      />
      <path
        fill="#448BCA"
        d="M21.8,1462.9c0,5.6-0.2,11.2,0.1,16.8c0.2,4.2-2.4,3.7-4.9,3.6c-2.6-0.1-5.6,0.9-5.5-3.7   c0.2-11.2,0.2-22.4,0-33.6c-0.1-4.7,3-3.5,5.5-3.6c2.6-0.1,5.2-0.6,4.9,3.7C21.5,1451.7,21.8,1457.3,21.8,1462.9z"
      />
      <path
        fill="#438BC9"
        d="M1018.2,1502.1c-0.2,2.9,0,5.8-0.6,8.6c-2.2,9.2-15.5,15.9-23.9,11.7c-3-1.5-0.8-5.2-1.2-7.9   c-0.3-1.9,1.2-2.1,2.7-2.1c2.4,0,4.8,0.1,7.2,0c3.4-0.1,5.4-2,5.5-5.5c0.1-3.6,0.3-7.2,0-10.7c-0.3-4.4,2.7-3.4,5.1-3.3   c2.2,0.1,5.6-1.4,5.3,3.1c-0.1,2,0,4.1,0,6.1C1018.1,1502.1,1018.2,1502.1,1018.2,1502.1z"
      />
      <path
        fill="#438BC9"
        d="M35.6,1522.5c-3.1,0-6.1,0.1-9.2,0c-8.1-0.4-14.5-6.7-14.9-14.7c-0.1-1.8-0.6-4.4,2.4-3.9   c2.7,0.5,6.9-2.6,7.7,2.6c0.7,4.5,3.2,5.9,7.4,5.9c5.4-0.1,10.9,0.1,16.3-0.1c4.7-0.1,2.7,3.4,2.9,5.4c0.1,2,1.3,5.1-2.9,4.9   C42.1,1522.4,38.8,1522.6,35.6,1522.5C35.6,1522.6,35.6,1522.5,35.6,1522.5z"
      />
    </g>
  </svg>
);

/*
 * This SVG is primarly used in the calendar as the left button to get to the previous month
 */
export const calendar_arrow_left_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 150 150"
  >
    <path
      fill="#004994"
      fillRule="evenodd"
      d="M113 75c0 2.209-1.791 4-4 4H50.639l18.126 18.175c1.56 1.565 1.557 4.097-.007 5.657-.781.779-1.803 1.168-2.825 1.168-1.025 0-2.051-.392-2.832-1.175l-24.933-25c-1.557-1.562-1.557-4.088 0-5.65l24.933-25c1.56-1.564 4.092-1.567 5.657-.007 1.564 1.56 1.567 4.092.007 5.657L50.639 71h58.36c2.21 0 4 1.791 4 4"
    />
  </svg>
);

/*
 * This SVG is primarly used in the calendar as the right button to get to the next month
 */
export const calendar_arrow_right_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 150 150"
  >
    <path
      fill="#004994"
      fillRule="evenodd"
      d="M111.832 77.825l-24.933 25c-.781.783-1.807 1.175-2.832 1.175-1.022 0-2.044-.39-2.825-1.168-1.564-1.56-1.567-4.092-.007-5.656L99.361 79H41c-2.209 0-4-1.791-4-4 0-2.21 1.791-4 4-4h58.361L81.235 52.825c-1.56-1.564-1.557-4.097.007-5.657 1.564-1.56 4.097-1.557 5.657.008l24.933 25c1.557 1.56 1.557 4.087 0 5.649"
    />
  </svg>
);

/*
 * default scroll to top icon
 */
export const scroll_to_top_icon = (arrowColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 0H32V32H0z"
        transform="translate(-1028 -172) translate(832 140) translate(32 32) translate(164) matrix(1 0 0 -1 0 32)"
      ></path>
      <path
        fill={arrowColor}
        fillRule="nonzero"
        d="M11.384 13.333h9.232c.638 0 .958.68.505 1.079l-4.613 4.07c-.28.246-.736.246-1.016 0l-4.613-4.07c-.453-.399-.133-1.079.505-1.079z"
        transform="translate(-1028 -172) translate(832 140) translate(32 32) translate(164) matrix(1 0 0 -1 0 32)"
      ></path>
    </g>
  </svg>
);

/*
 * OpenAI Logo
 */
export const openai_logo = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 2406 2406"
  >
    <path
      d="M1 578.4C1 259.5 259.5 1 578.4 1h1249.1c319 0 577.5 258.5 577.5 577.4V2406H578.4C259.5 2406 1 2147.5 1 1828.6V578.4z"
      fill="#74aa9c"
    />
    <path
      d="M1107.3 299.1c-198 0-373.9 127.3-435.2 315.3C544.8 640.6 434.9 720.2 370.5 833c-99.3 171.4-76.6 386.9 56.4 533.8-41.1 123.1-27 257.7 38.6 369.2 98.7 172 297.3 260.2 491.6 219.2 86.1 97 209.8 152.3 339.6 151.8 198 0 373.9-127.3 435.3-315.3 127.5-26.3 237.2-105.9 301-218.5 99.9-171.4 77.2-386.9-55.8-533.9v-.6c41.1-123.1 27-257.8-38.6-369.8-98.7-171.4-297.3-259.6-491-218.6-86.6-96.8-210.5-151.8-340.3-151.2zm0 117.5-.6.6c79.7 0 156.3 27.5 217.6 78.4-2.5 1.2-7.4 4.3-11 6.1L952.8 709.3c-18.4 10.4-29.4 30-29.4 51.4V1248l-155.1-89.4V755.8c-.1-187.1 151.6-338.9 339-339.2zm434.2 141.9c121.6-.2 234 64.5 294.7 169.8 39.2 68.6 53.9 148.8 40.4 226.5-2.5-1.8-7.3-4.3-10.4-6.1l-360.4-208.2c-18.4-10.4-41-10.4-59.4 0L1024 984.2V805.4L1372.7 604c51.3-29.7 109.5-45.4 168.8-45.5zM650 743.5v427.9c0 21.4 11 40.4 29.4 51.4l421.7 243-155.7 90L597.2 1355c-162-93.8-217.4-300.9-123.8-462.8C513.1 823.6 575.5 771 650 743.5zm807.9 106 348.8 200.8c162.5 93.7 217.6 300.6 123.8 462.8l.6.6c-39.8 68.6-102.4 121.2-176.5 148.2v-428c0-21.4-11-41-29.4-51.4l-422.3-243.7 155-89.3zM1201.7 997l177.8 102.8v205.1l-177.8 102.8-177.8-102.8v-205.1L1201.7 997zm279.5 161.6 155.1 89.4v402.2c0 187.3-152 339.2-339 339.2v-.6c-79.1 0-156.3-27.6-217-78.4 2.5-1.2 8-4.3 11-6.1l360.4-207.5c18.4-10.4 30-30 29.4-51.4l.1-486.8zM1380 1421.9v178.8l-348.8 200.8c-162.5 93.1-369.6 38-463.4-123.7h.6c-39.8-68-54-148.8-40.5-226.5 2.5 1.8 7.4 4.3 10.4 6.1l360.4 208.2c18.4 10.4 41 10.4 59.4 0l421.9-243.7z"
      fill="white"
    />
  </svg>
);

export const separator_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.separator-cls-1{fill:none;stroke-miterlimit:10;stroke-width:2px;}.separator-cls-2{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="separator-cls-1" x="1" y="1" width="63.57" height="62.61" />
      <polyline
        class="separator-cls-1"
        points="48.05 16.3 48.05 26.86 17.35 26.86 17.35 16.62"
      />
      <polyline
        class="separator-cls-1"
        points="48.05 48.3 48.05 37.75 17.35 37.75 17.35 47.98"
      />
      <polygon
        class="separator-cls-2"
        points="9.19 29.83 9.76 29.01 13.91 31.91 9.75 34.81 9.17 33.99 12.14 31.9 9.19 29.83"
      />
      <polygon
        class="separator-cls-2"
        points="56.39 29.83 55.81 29.01 51.67 31.91 55.83 34.81 56.4 33.99 53.43 31.9 56.39 29.83"
      />
    </g>
  </svg>
);

/*
 * AM headline svg
 */
export const title_am_icon = (classNames, width, height, style) => (
  <svg
    className={classNames}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    role="img"
    style={style ? style : {}}
    viewBox="0 0 65.57 64.61"
  >
    <defs>
      <style>{`.am_cls-1{fill:none;stroke-width:2px;}.am_cls-1,.cls-2{stroke-miterlimit:10;}.am_cls-3{stroke-width:0px;}`}</style>
    </defs>
    <g>
      <rect class="am_cls-1" x="1" y="1" width="63.57" height="62.61" />
      <path
        class="am_cls-2"
        d="m31.67,20.39l.26,5.59h-.67c-.13-.98-.3-1.69-.53-2.11-.36-.68-.85-1.18-1.45-1.5s-1.4-.48-2.38-.48h-3.36v18.21c0,1.46.16,2.38.47,2.74.45.49,1.13.74,2.06.74h.83v.65h-10.11v-.65h.84c1.01,0,1.72-.3,2.14-.91.26-.38.39-1.23.39-2.57v-18.21h-2.87c-1.11,0-1.9.08-2.37.25-.61.22-1.13.65-1.56,1.28s-.69,1.49-.77,2.57h-.67l.28-5.59h19.46Z"
      />
      <polygon
        class="am_cls-3"
        points="39.99 30.6 45.95 44.55 33.22 44.55 33.22 30.63 39.99 30.6"
      />
      <polygon
        class="am_cls-3"
        points="41.75 30.6 47.71 44.28 51.78 44.28 46.09 30.6 41.75 30.6"
      />
      <polygon
        class="am_cls-3"
        points="48.12 30.6 54.08 44.28 58.14 44.28 52.45 30.6 48.12 30.6"
      />
    </g>
  </svg>
);
